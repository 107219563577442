import React, { createContext, useContext, useEffect, useState } from 'react';

import { IDropdownOption, IPaginationResponse } from '@/@types';
import { IUser } from '@/@types/auth';
import { Company, Department } from '@/@types/metas';
import useDebounce from '@/hooks/useDebounce';
import { useGenericQuery } from '@/hooks/useQueryData';
import { fetchCompanies, fetchDepartments } from '@/services/metas';
import { fetchRecruiters } from '@/services/users';
import { GetFormattedName } from '@/utils/common';

import { useAuth } from './AuthProvider';

interface CommonDataContextProps {
  companyPage: number;
  setCompanyPage: React.Dispatch<React.SetStateAction<number>>;
  companies: string[];
  loadingCompanies: boolean | null;
  departmentPage: number;
  setDepartmentPage: React.Dispatch<React.SetStateAction<number>>;
  department: IDropdownOption[];
  loadingDepartments: boolean | null;
  totalDepartments: number;
  totalCompanies: number;
  setCompanySearch: React.Dispatch<React.SetStateAction<string>>;
  setDepartmentSearch: React.Dispatch<React.SetStateAction<string>>;
  haveMoreDepartmentPage: boolean | null;
  haveMoreCompanyPage: boolean | null;
  setCompanies: React.Dispatch<React.SetStateAction<string[]>>;
  setDepartment: React.Dispatch<React.SetStateAction<IDropdownOption[]>>;
  recruiters: IDropdownOption[];
  loadingRecruiters: boolean | null;
  recruiterPage: number;
  setRecruitersSearch: React.Dispatch<React.SetStateAction<string>>;
  setRecruiterPage: React.Dispatch<React.SetStateAction<number>>;
  haveMoreRecruitersPage: boolean | null;
}

const defaultCommonDataContext: CommonDataContextProps = {
  companyPage: 0,
  setCompanyPage: () => {},
  companies: [],
  loadingCompanies: null,
  departmentPage: 0,
  setDepartmentPage: () => {},
  department: [],
  loadingDepartments: null,
  totalDepartments: 0,
  totalCompanies: 0,
  setCompanySearch: () => {},
  setDepartmentSearch: () => {},
  haveMoreDepartmentPage: null,
  haveMoreCompanyPage: null,
  setCompanies: () => {},
  setDepartment: () => {},
  recruiters: [],
  loadingRecruiters: null,
  recruiterPage: 0,
  setRecruitersSearch: () => {},
  setRecruiterPage: () => {},
  haveMoreRecruitersPage: null,
};

const CommonDataContext = createContext<CommonDataContextProps>(
  defaultCommonDataContext,
);

export const CommonDataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { token } = useAuth();

  const [companyPage, setCompanyPage] = useState(0);
  const [recruiterPage, setRecruiterPage] = useState(0);
  const [companySearch, setCompanySearch] = useState('');
  const [departmentPage, setDepartmentPage] = useState(0);
  const [recruitersSearch, setRecruitersSearch] = useState('');
  const [departmentSearch, setDepartmentSearch] = useState('');
  const [companies, setCompanies] = useState<string[]>([]);
  const [department, setDepartment] = useState<IDropdownOption[]>([]);
  const [recruiters, setRecruiters] = useState<IDropdownOption[]>([]);

  const companyDebouncedSearchText = useDebounce(companySearch);
  const departmentDebouncedSearchText = useDebounce(departmentSearch);
  const recruiterDebouncedSearchText = useDebounce(recruitersSearch);

  const { data: departmentList, isLoading: loadingDepartments } =
    useGenericQuery(
      ['getDepartments', departmentPage, departmentDebouncedSearchText],
      () => fetchDepartments(departmentPage + 1, departmentDebouncedSearchText),
      { enabled: !!token },
    );

  const { data: companiesList, isLoading: loadingCompanies } = useGenericQuery(
    ['getCompanies', companyPage, companyDebouncedSearchText],
    () => fetchCompanies(companyPage + 1, companyDebouncedSearchText),
    { enabled: !!token },
  );

  const { data: recruitersList, isLoading: loadingRecruiters } =
    useGenericQuery(
      ['fetchRecruiters', recruiterPage, recruiterDebouncedSearchText],
      () => fetchRecruiters(recruiterPage + 1, recruiterDebouncedSearchText),
      { enabled: !!token },
    );

  useEffect(() => {
    if (departmentDebouncedSearchText) {
      setDepartmentPage(0);
      setDepartment([]);
    }
    if (typeof departmentList === 'object') {
      const newDepartments = departmentList?.results?.map((item) => ({
        label: item?.name,
        value: item.name,
        extra: item?.override,
      }));

      setDepartment((prevDepartments) =>
        departmentDebouncedSearchText
          ? newDepartments
          : [...prevDepartments, ...newDepartments],
      );
    }
  }, [departmentList, departmentDebouncedSearchText]);

  useEffect(() => {
    if (companyDebouncedSearchText) {
      setCompanyPage(0);
      setCompanies([]);
    }
    if (typeof companiesList === 'object') {
      const newCompanies = companiesList?.results?.map((item) => item.name);
      setCompanies((prevCompanies) =>
        companyDebouncedSearchText
          ? newCompanies
          : [...prevCompanies, ...newCompanies],
      );
    }
  }, [companiesList, companyDebouncedSearchText]);

  useEffect(() => {
    if (recruiterDebouncedSearchText) {
      setRecruiterPage(0);
      setRecruiters([]);
    }
    if (typeof recruitersList === 'object') {
      const newRecruiters = recruitersList?.results?.map((item) => ({
        label: GetFormattedName(item),
        value: item._id!,
        avatar: item?.avatar as string,
      }));
      setRecruiters((prevRec) =>
        recruiterDebouncedSearchText
          ? newRecruiters
          : [...prevRec, ...newRecruiters],
      );
    }
  }, [recruitersList, recruiterDebouncedSearchText]);

  const totalDepartments =
    (departmentList as IPaginationResponse<Department>)?.totalPages ?? 0;
  const totalCompanies =
    (companiesList as IPaginationResponse<Company>)?.totalPages ?? 0;
  const haveMoreDepartmentPage = departmentPage + 1 < totalDepartments;
  const haveMoreCompanyPage = companyPage + 1 < totalCompanies;
  const haveMoreRecruitersPage =
    recruiterPage + 1 <
    (recruitersList as IPaginationResponse<IUser>)?.totalPages;

  return (
    <CommonDataContext.Provider
      value={{
        companyPage,
        setCompanyPage,
        companies,
        loadingCompanies,
        departmentPage,
        setDepartmentPage,
        department,
        loadingDepartments,
        totalDepartments,
        totalCompanies,
        setCompanySearch,
        setDepartmentSearch,
        haveMoreDepartmentPage,
        haveMoreCompanyPage,
        setCompanies,
        setDepartment,
        recruiters,
        loadingRecruiters,
        recruiterPage,
        setRecruitersSearch,
        setRecruiterPage,
        haveMoreRecruitersPage,
      }}
    >
      {children}
    </CommonDataContext.Provider>
  );
};

export const useCommonDataContext = () => {
  const context = useContext(CommonDataContext);
  if (!context) {
    throw new Error(
      'useCommonContext must be used within a CommonDataProvider',
    );
  }
  return context;
};
