/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { HiOutlineTrash } from 'react-icons/hi';
import { RiDragMoveFill } from 'react-icons/ri';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { FilterOptions, IDropdownOption, IPaginationResponse } from '@/@types';
import {
  IAssigneeData,
  IAssignMyselfTicketsPayload,
  IAssignTicketsPayload,
  IRate,
  ITicket,
} from '@/@types/tickets';
import ConfirmationModal from '@/components/ConfirmationModal';
import Container from '@/components/Container';
import { DataTable } from '@/components/DataTable';
import ReactDropdown from '@/components/Dropdown';
import HeaderWithSearchAndFilter from '@/components/HeaderWithSearchAndFilter';
import Modal from '@/components/Modal';
import TablePagination from '@/components/Table/TablePagination';
import TableSize from '@/components/Table/TableSize';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import {
  TICKET_CANDIDATES_TABS,
  TICKET_FILTER_MODAL_FIELDS,
  TICKET_TABS,
  TICKETS_ACTION_ENUM,
  TicketTabsList,
} from '@/constants/ticket';
import { useGenericMutation } from '@/hooks/useMutationData';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useCommonDataContext } from '@/provider/CommonDataProvider';
import { useFilters } from '@/provider/FiltersProvider';
import { usePagination } from '@/provider/PaginationProvider';
import { ROUTES } from '@/routes';
import {
  assignTickets,
  assignTicketsToMyself,
  deleteTicket,
  getTickets,
} from '@/services/ticket';
import {
  createInitialFilterState,
  GetFormattedName,
  hasValidFilters,
  snakeToTitleCase,
} from '@/utils/common';
import { getLocalDate } from '@/utils/dateTime';
import { Table } from '@tanstack/react-table';

import AppliedFilterSection from '../Candidate/components/AppliedFilterSection';
import FilterModal from '../Candidate/components/FilterModal';

import { TicketsTableColumns } from './components/table/columns';

const TicketsScreen = () => {
  const staticText = strings.ticketScreen;
  const isSmallScreen = window.innerWidth < 768;

  const navigate = useNavigate();
  const { tab } = useParams<{ tab: TICKET_TABS }>();
  const { isAdmin, isRecruiter, isCompanyClient, hasSubRole } = useAuth();
  const {
    haveMoreRecruitersPage,
    recruiters,
    setRecruitersSearch,
    loadingRecruiters,
    setRecruiterPage,
  } = useCommonDataContext();
  const { pageSize, setPageSize, pageIndex, setPageIndex, resetPagination } =
    usePagination();

  const [tableData, setTableData] = useState<ITicket[]>([]);
  const {
    ticketFilters,
    setTicketFilters,
    ticketSearchText,
    setTicketSearchText,
  } = useFilters();
  const [selectedTicketId, setSelectedTicketId] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<ITicket[]>([]);
  const [assignedRecruiterId, setAssignedRecruiterId] = useState('');
  const [tableState, setTableState] = useState<Table<ITicket> | null>(null);
  const [activeTab, setActiveTab] = useState<TICKET_TABS>(
    tab || TICKET_TABS.ALL_TICKETS,
  );

  const updatedTicketFilters = {
    ...ticketFilters,
    assignee: (ticketFilters?.assignee as IDropdownOption)?.value,
  };

  const { data, isLoading, refetch } = useGenericQuery(
    [
      'getTickets',
      activeTab,
      pageSize,
      pageIndex,
      ticketSearchText,
      ...Object.values(ticketFilters),
    ],
    () =>
      getTickets({
        tab: activeTab,
        queryParams: {
          pageSize,
          page: pageIndex + 1,
          search: ticketSearchText,
          ...updatedTicketFilters,
        },
      }),
    {
      enabled: TicketTabsList.includes(activeTab),
    },
  );

  const {
    companies,
    haveMoreCompanyPage,
    department,
    haveMoreDepartmentPage,
    loadingDepartments,
    setCompanyPage,
    setDepartmentPage,
    setDepartmentSearch,
    setCompanySearch,
    loadingCompanies,
  } = useCommonDataContext();

  const tags = useMemo(() => {
    return Object.entries(ticketFilters)
      .filter(([, value]) => value)
      .map(([key, value]) => {
        return {
          key,
          value:
            typeof value === 'object'
              ? ((value as IDropdownOption).label ?? '')
              : value || '',
        };
      });
  }, [ticketFilters]);

  const fields = TICKET_FILTER_MODAL_FIELDS(
    department,
    loadingDepartments,
    haveMoreDepartmentPage,
    setDepartmentPage,
    setDepartmentSearch,
    companies,
    loadingCompanies,
    haveMoreCompanyPage,
    setCompanyPage,
    setCompanySearch,
    recruiters,
    loadingRecruiters,
    isCompanyClient,
    isRecruiter,
  );

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty, isSubmitted },
  } = useForm<FilterOptions>({
    mode: 'all',
    defaultValues: createInitialFilterState(fields),
  });

  const deleteTicketMutation = useGenericMutation<string, string | boolean>(
    deleteTicket,
    {
      onSuccess: (response) => {
        if (response) {
          toast.success(staticText.deleted);
          refetch();
        }
        setOpenDeleteModal(false);
      },
    },
  );

  const moveToMyTicketsMutation = useGenericMutation<
    IAssignMyselfTicketsPayload,
    ITicket | boolean
  >(({ ids }) => assignTicketsToMyself({ ids }), {
    onSuccess: (response) => {
      if (response) {
        toast.success(staticText.assigned);
        refetch();
        setSelectedRows([]);
        tableState?.resetRowSelection();
      }
    },
  });

  const assignTicketsMutation = useGenericMutation<
    IAssignTicketsPayload,
    ITicket | boolean
  >((params) => assignTickets(params), {
    onSuccess: (response) => {
      if (response) {
        {
          assignedRecruiterId
            ? toast.success(staticText.assignedToRecruiter)
            : null;
        }
        refetch();
        closeAssignModal();
      }
    },
  });

  const showMoveToButton =
    selectedRows.length && activeTab === TICKET_TABS.ALL_TICKETS;

  const handleSelectionChange = useCallback((selectedRows: ITicket[]) => {
    setSelectedRows(selectedRows);
  }, []);

  const handleTabChange = (state: TICKET_TABS) => {
    setActiveTab(state);
    const path = generatePath(ROUTES.TICKETS_WITH_TAB, { tab: state });
    navigate(path);
    setSelectedRows([]);
    tableState?.resetRowSelection();
    resetPagination();
  };

  const handleRowClick = (data: ITicket) => {
    if (isRecruiter) {
      const path = generatePath(ROUTES.TICKET_DETAIL_WITH_TAB, {
        tab: activeTab,
        ticketId: data._id,
        innerTab: TICKET_CANDIDATES_TABS.APPLIED_CANDIDATES,
      });
      navigate(path, { replace: true });
    } else {
      navigate(`${ROUTES.TICKETS}/${data?._id}`);
    }
  };

  const handleMove = () => {
    const selectedRowsIds = selectedRows.map((row) => row._id!);
    if (selectedRowsIds.length)
      moveToMyTicketsMutation.mutate({
        ids: selectedRowsIds,
      });
  };

  const toggleFilterModal = () => setFilterModalOpen((prev) => !prev);

  const handleAction = (action: string, ticketId: string) => {
    setSelectedTicketId(ticketId);
    if (action === TICKETS_ACTION_ENUM.ASSIGN_TO) setIsAssignModalOpen(true);
    if (action === TICKETS_ACTION_ENUM.UN_ASSIGN) handleUnassign(ticketId);
    if (action === TICKETS_ACTION_ENUM.DELETE) setOpenDeleteModal(true);
  };

  const handleAssignRecruiter = () => {
    assignTicketsMutation.mutate({
      userId: assignedRecruiterId,
      id: selectedTicketId,
      isAssign: true,
    });
  };

  const closeAssignModal = () => {
    setIsAssignModalOpen(false);
    setAssignedRecruiterId('');
    setSelectedTicketId('');
  };

  const handleUnassign = (id: string) => {
    const selectedTicket = tableData.find((option) => option._id === id);
    toast.promise(
      assignTicketsMutation.mutateAsync({
        userId: selectedTicket!.assignee!._id!,
        id,
        isAssign: false,
      }),
      {
        loading: staticText.unassigning,
        success: staticText.unassignedTicket,
        error: (error) => error.message,
      },
    );
  };

  const handleDeleteTicket = () => {
    if (selectedTicketId) {
      deleteTicketMutation.mutate(selectedTicketId);
    }
  };

  const handleFilter: SubmitHandler<FilterOptions> = ({
    rate,
    postedOn,
    ...restData
  }) => {
    const { rateMin = 0, rateMax = 0 } =
      typeof rate === 'object' &&
      rate !== null &&
      'rateMin' in rate &&
      'rateMax' in rate
        ? (rate as IRate)
        : { rateMin: 0, rateMax: 0 };
    setTicketFilters({
      ...restData,
      postedOn: getLocalDate(postedOn as string) || '',
      rateMin,
      rateMax,
      status: (restData?.status as IDropdownOption)?.value,
    });

    setFilterModalOpen(false);
  };

  const resetFilter = () => {
    setTicketFilters({});
    reset(createInitialFilterState(fields));
    setTableData((data as IPaginationResponse<ITicket>)?.results);
  };

  useEffect(() => {
    if (isRecruiter) {
      if (!tab || !Object.values(TICKET_TABS).includes(tab as TICKET_TABS)) {
        const path = generatePath(ROUTES.TICKETS_WITH_TAB, {
          tab: TICKET_TABS.ALL_TICKETS,
        });
        navigate(path, { replace: true });
      } else {
        setActiveTab(tab);
        handleTabChange(tab as TICKET_TABS);
      }
    }
  }, [tab, navigate]);

  useEffect(() => {
    if (typeof data === 'object') {
      setTableData(data?.results);
    }
  }, [data]);

  const selectedTicket = tableData.find(
    (option) => option._id === selectedTicketId,
  );
  const assignee = selectedTicket?.assignee
    ? GetFormattedName<IAssigneeData>(selectedTicket.assignee)
    : '';

  useEffect(() => {
    if (Object.keys(ticketFilters).length > 0) {
      reset({
        ...ticketFilters,
        rate: {
          rateMin: ticketFilters.rateMin,
          rateMax: ticketFilters.rateMax,
        } as IRate,
      });
    }
  }, [ticketFilters, reset]);

  const handleRemoveTag = (keyToRemove: string) => {
    setTicketFilters({ ...ticketFilters, [keyToRemove]: '' });
    reset({
      ...ticketFilters,
      rate: ['rateMin', 'rateMax'].includes(keyToRemove)
        ? { ...ticketFilters, [keyToRemove]: '' }
        : ticketFilters.rate,
      [keyToRemove]: '',
    });
  };

  useEffect(() => {
    if (hasValidFilters(ticketFilters) || ticketSearchText.length) {
      setPageIndex(0);
    }
  }, [ticketSearchText, ticketFilters]);

  return (
    <>
      {filterModalOpen ? (
        <FilterModal
          isOpen={filterModalOpen}
          onClose={toggleFilterModal}
          handleApplyFilter={handleSubmit(handleFilter)}
          control={control}
          handleResetFilter={resetFilter}
          filterErrors={errors}
          buttonDisabled={Object.values(watch()).every((value) => !value)}
          fields={fields}
          searchText={ticketSearchText}
          handleClearSearch={() => setTicketSearchText('')}
        />
      ) : null}
      <ConfirmationModal
        open={openDeleteModal}
        handleCloseModal={() => setOpenDeleteModal(false)}
        primaryBtnLabel={strings.common.delete}
        primaryBtnAction={handleDeleteTicket}
        icon={<HiOutlineTrash className='size-6 text-tomatoRed' />}
        textDesc={strings.deleteText}
        isLoading={deleteTicketMutation.isPending}
        containerClassName='py-5'
      />
      <Modal
        title={staticText.assignRecruiter}
        open={isAssignModalOpen}
        handleCloseModal={closeAssignModal}
        primaryBtnLabel={staticText.assign}
        primaryBtnAction={handleAssignRecruiter}
        buttonDisabled={
          !assignedRecruiterId ||
          assignedRecruiterId === selectedTicket?.assignee?._id
        }
        isLoading={assignTicketsMutation.isPending}
      >
        <>
          <Typography className='block font-bold mb-1'>
            {staticText.recruiter}
          </Typography>
          <ReactDropdown
            options={recruiters}
            value={
              recruiters.find(
                (option) => option.value === assignedRecruiterId,
              ) || { value: '', label: assignee }
            }
            onChange={(option) => setAssignedRecruiterId(option as string)}
            errors={errors}
            isSearchable
            name='recruiter'
            placeholder={strings.common.select}
            inputHeight='42px'
            borderRadius='12px'
            isLoading={!!loadingRecruiters}
            haveMoreOptions={!!haveMoreRecruitersPage}
            setPage={setRecruiterPage}
            setInputSearch={setRecruitersSearch}
          />
        </>
      </Modal>
      <Container className='h-[calc(100vh-180px)]'>
        <HeaderWithSearchAndFilter
          searchText={ticketSearchText}
          placeholder={
            isCompanyClient
              ? staticText.headerSearchPlaceholder
              : staticText.headerSearchPlaceholderWithCompanySearch
          }
          setSearchText={setTicketSearchText}
          toggleFilterModal={toggleFilterModal}
          title={staticText.tickets}
          haveFilterData={
            (isDirty && isSubmitted) || hasValidFilters(ticketFilters)
          }
        />
        {hasValidFilters(ticketFilters) ? (
          <AppliedFilterSection
            tags={tags}
            className='mb-3'
            handleRemoveTag={handleRemoveTag}
          />
        ) : null}
        <div className='mb-2'>
          {isRecruiter ? (
            <div className='flex justify-between'>
              <div className='flex gap-2'>
                {TicketTabsList.map((tab) => (
                  <Button
                    key={tab}
                    variant={activeTab === tab ? 'default' : 'secondary'}
                    onClick={() => handleTabChange(tab as TICKET_TABS)}
                    className={cn(
                      'inline-flex items-center justify-center whitespace-nowrap rounded-md md:rounded-xl px-2 md:px-4 h-8 md:h-[40px] text-sm md:text-base transition-all disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300 capitalize',
                      {
                        'font-bold shadow-sm': activeTab === tab,
                      },
                    )}
                  >
                    {snakeToTitleCase(tab)}
                  </Button>
                ))}
              </div>
              {showMoveToButton ? (
                <Button
                  icon={<RiDragMoveFill className='text-base' />}
                  iconPosition='left'
                  variant='outline'
                  onClick={handleMove}
                  loading={moveToMyTicketsMutation.isPending}
                  className='w-fit h-8 md:h-[40px]'
                >
                  {isSmallScreen ? '' : staticText.moveTo}
                </Button>
              ) : null}
            </div>
          ) : null}
          {isCompanyClient || (isAdmin && !hasSubRole) ? (
            <div className='flex justify-end'>
              <Button
                onClick={() => navigate(ROUTES.CREATE_TICKET)}
                className='w-full md:w-32'
              >
                {staticText.createTicket}
              </Button>
            </div>
          ) : null}
        </div>
        <div className='flex-grow overflow-auto scrollbarHidden'>
          <DataTable
            columns={TicketsTableColumns(
              isAdmin,
              isRecruiter,
              activeTab,
              handleAction,
              hasSubRole,
            )}
            handleRowClick={handleRowClick}
            data={tableData}
            onRowSelectionChange={handleSelectionChange}
            setTablesState={setTableState}
            pageCount={(data as IPaginationResponse<ITicket>)?.totalPages}
            loading={isLoading}
          />
        </div>
      </Container>
      {isLoading ? null : (
        <div className='flex justify-between items-center mt-3 md:mt-5 flex-col md:flex-row gap-2'>
          <TableSize
            tableState={tableState}
            setTableState={setTableState}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
          <TablePagination
            tableState={tableState}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        </div>
      )}
    </>
  );
};

export default TicketsScreen;
