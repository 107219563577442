import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { USER_TYPE } from '@/constants';
import { cn } from '@/lib/utils';
import { useAuth } from '@/provider/AuthProvider';

import { ROUTES } from '.';

interface ProtectedRouteProps {
  recruiterElement?: React.ReactNode;
  companyClientElement?: React.ReactNode;
  adminElement?: React.ReactNode;
  requiredRoles?: USER_TYPE[];
  defaultElement?: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  recruiterElement,
  companyClientElement,
  adminElement,
  requiredRoles,
  defaultElement,
}) => {
  const isSmallScreen = window.innerWidth < 768;
  const { token, isAdmin, isCompanyClient, isRecruiter } = useAuth();
  const location = useLocation();

  const redirectPath = location.pathname;
  const isNotificationScreen =
    isSmallScreen && location.pathname.includes(ROUTES.NOTIFICATIONS);

  const getElementToRender = () => {
    if (isRecruiter && recruiterElement) return recruiterElement;
    if (isCompanyClient && companyClientElement) return companyClientElement;
    if (isAdmin && adminElement) return adminElement;
    if (defaultElement) return defaultElement;
    return null;
  };

  if (!token) {
    return <Navigate to={ROUTES.LOGIN} state={{ from: redirectPath }} />;
  }

  const hasRequiredRole = requiredRoles?.some((role) => {
    switch (role) {
      case USER_TYPE.ADMIN:
        return isAdmin;
      case USER_TYPE.COMPANY_CLIENT:
        return isCompanyClient;
      case USER_TYPE.RECRUITER:
        return isRecruiter;
      default:
        return false;
    }
  });

  if (!hasRequiredRole) {
    return <Navigate to='/unauthorized' state={{ from: redirectPath }} />;
  }

  return (
    <div
      className={cn('p-3 md:p-6 flex-1 overflow-auto', {
        'md:py-4': location.pathname === ROUTES.DASHBOARD,
        'p-0': isNotificationScreen,
      })}
    >
      {getElementToRender()}
    </div>
  );
};

export default ProtectedRoute;
