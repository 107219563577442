import React from 'react';
import { ChevronsLeft, ChevronsRight } from 'lucide-react';

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination';
import { Table } from '@tanstack/react-table';

interface Props<TData> {
  tableState: Table<TData> | null;
  pageIndex: number;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
}

const TablePagination = <TData,>({
  tableState,
  pageIndex,
  setPageIndex,
}: Props<TData>) => {
  const indexButtonCount = 5;

  const pageCount = tableState?.getPageCount() || 0;
  const showEllipseAndLastPageIndex =
    pageCount > indexButtonCount && pageCount - pageIndex > 4;

  const getIndexes = () => {
    if (pageIndex === 0) {
      if (pageCount > indexButtonCount) {
        // Show the first 3 pages plus ellipse and last page
        return tableState?.getPageOptions()?.slice(0, 3);
      }
      // Show all buttons from 0 to pageCount and hide ellipse and last page
      return tableState?.getPageOptions()?.slice(0, indexButtonCount);
    } else if (pageIndex >= pageCount - 4) {
      // Show all buttons from pageCount - 4 to pageCount are visible, hide ellipse and last page
      return tableState?.getPageOptions()?.slice(-indexButtonCount);
    }
    // Show current, previous and next page
    return tableState?.getPageOptions()?.slice(pageIndex - 1, pageIndex + 2);
  };

  const handleGoToPreviousPage = () => {
    if (tableState?.getCanPreviousPage()) {
      setPageIndex((prev) => prev - 1);
      tableState?.previousPage();
    }
  };
  const handleGoToIndex = (selectedPageIndex: number) => {
    setPageIndex(selectedPageIndex);
    tableState?.setPageIndex(selectedPageIndex);
  };
  const handleGoToNextPage = () => {
    if (tableState?.getCanNextPage()) {
      setPageIndex((prev) => prev + 1);
      tableState?.nextPage();
    }
  };

  if (pageCount < 2) return;
  return (
    <Pagination>
      <PaginationContent>
        <PaginationLink
          aria-label='Go to first page'
          className='cursor-pointer'
          onClick={() => handleGoToIndex(0)}
          disabled={!tableState?.getCanPreviousPage()}
        >
          <ChevronsLeft className='h-4 w-4 text-greyishBlack' />
        </PaginationLink>
        <PaginationPrevious
          onClick={handleGoToPreviousPage}
          disabled={!tableState?.getCanPreviousPage()}
          className='cursor-pointer'
        />
        {getIndexes()?.map((selectedPageIndex) => (
          <PaginationItem key={selectedPageIndex}>
            <PaginationLink
              isActive={pageIndex === selectedPageIndex}
              onClick={() => handleGoToIndex(selectedPageIndex)}
            >
              {selectedPageIndex + 1}
            </PaginationLink>
          </PaginationItem>
        ))}
        {showEllipseAndLastPageIndex ? (
          <>
            <PaginationItem>
              <PaginationEllipsis />
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                isActive={pageIndex === pageCount - 1}
                onClick={() => handleGoToIndex(pageCount - 1)}
              >
                {pageCount}
              </PaginationLink>
            </PaginationItem>
          </>
        ) : null}
        <PaginationNext
          onClick={handleGoToNextPage}
          disabled={!tableState?.getCanNextPage()}
          className='cursor-pointer'
        />
        <PaginationLink
          className='cursor-pointer'
          aria-label='Go to last page'
          onClick={() => handleGoToIndex(pageCount - 1)}
          disabled={!tableState?.getCanNextPage()}
        >
          <ChevronsRight className='h-4 w-4 text-greyishBlack' />
        </PaginationLink>
      </PaginationContent>
    </Pagination>
  );
};

export default TablePagination;
