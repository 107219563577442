/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { FaCheckCircle } from 'react-icons/fa';
import { IoMdCloseCircle } from 'react-icons/io';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { FilterOptions, IDropdownOption, IPaginationResponse } from '@/@types';
import { IApprovalPayload, IApprovals } from '@/@types/approvals';
import { IUser } from '@/@types/auth';
import { ICandidate, IPersonnelActionFormData } from '@/@types/candidate';
import { ClientData } from '@/@types/tickets';
import ConfirmationModal from '@/components/ConfirmationModal';
import Container from '@/components/Container';
import { DataTable } from '@/components/DataTable';
import HeaderWithSearchAndFilter from '@/components/HeaderWithSearchAndFilter';
import Modal from '@/components/Modal';
import TablePagination from '@/components/Table/TablePagination';
import TableSize from '@/components/Table/TableSize';
import { Button } from '@/components/ui/button';
import { SUB_ROLE } from '@/constants';
import {
  AccountOfficerStatus,
  APPROVAL_TABS,
  ApprovalTabsList,
  getApprovalsFilterModalFields,
  RecruiterManagerStatus,
} from '@/constants/approval';
import useDebounce from '@/hooks/useDebounce';
import { useGenericMutation } from '@/hooks/useMutationData';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useCandidate } from '@/provider/CandidateProvider';
import { useCommonDataContext } from '@/provider/CommonDataProvider';
import { useFilters } from '@/provider/FiltersProvider';
import { usePagination } from '@/provider/PaginationProvider';
import { useSidebarContext } from '@/provider/SidebarProvider';
import { ROUTES } from '@/routes';
import {
  accountOfficerApproval,
  fetchApprovals,
  getPanFormById,
  recruiterManagerApproval,
} from '@/services/approvals';
import { getCandidatesListForChat } from '@/services/chat';
import {
  createInitialFilterState,
  GetFormattedName,
  hasValidFilters,
  snakeToTitleCase,
} from '@/utils/common';
import { Table } from '@tanstack/react-table';

import AppliedFilterSection from '../Candidate/components/AppliedFilterSection';
import PersonnelActionHistory from '../Candidate/components/EditModal/PersonnelActionFormHistorySection';
import FilterModal from '../Candidate/components/FilterModal';

import { APPROVAL_TABLE_COLUMNS } from './components/table/columns';

const Approvals = () => {
  const staticText = strings.approvalsScreen;
  const isSmallScreen = window.innerWidth < 768;
  const { tab } = useParams<{ tab: APPROVAL_TABS }>();

  const { user } = useAuth();
  const navigate = useNavigate();
  const { setEditableCandidateData } = useCandidate();
  const { setFromApprovalScreen } = useSidebarContext();
  const { pageSize, pageIndex, setPageIndex, setPageSize, resetPagination } =
    usePagination();
  const {
    haveMoreRecruitersPage,
    recruiters,
    setRecruitersSearch,
    loadingRecruiters,
    setRecruiterPage,
  } = useCommonDataContext();
  const {
    approvalFilters,
    setApprovalFilters,
    approvalSearchText,
    setApprovalSearchText,
  } = useFilters();

  const tags = useMemo(() => {
    return Object.entries(approvalFilters)
      .filter(([, value]) => value)
      .map(([key, value]) => {
        return {
          key,
          value:
            typeof value === 'object'
              ? ((value as IDropdownOption).label ?? '')
              : value || '',
        };
      });
  }, [approvalFilters]);

  // Approvals requests are PAN forms list
  const [panFormId, setPanFormId] = useState('');
  const [candidatePage, setCandidatePage] = useState(0);
  const [candidateSearch, setCandidateSearch] = useState('');
  const [tableData, setTabelData] = useState<IApprovals[]>([]);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState<IApprovals[]>([]);
  const [candidateList, setCandidateList] = useState<IDropdownOption[]>([]);
  const [tableState, setTableState] = useState<Table<IApprovals> | null>(null);
  const [activeTab, setActiveTab] = useState<APPROVAL_TABS>(
    tab || APPROVAL_TABS.PENDING,
  );

  const { data, isLoading, refetch } = useGenericQuery(
    [
      'approvals',
      pageIndex,
      activeTab,
      pageSize,
      approvalSearchText,
      user?.subRole,
      ...Object.values(approvalFilters),
    ],
    () =>
      fetchApprovals({
        tab: activeTab,
        subRole: user!.subRole!,
        pageSize,
        page: pageIndex + 1,
        type: user!.subRole!,
        search: approvalSearchText,
        ...approvalFilters,
        assignee: (approvalFilters?.assignee as IDropdownOption)?.label || '',
      }),
    { enabled: ApprovalTabsList.includes(activeTab) },
  );

  const debouncedCanidateSearchText = useDebounce(candidateSearch);

  const { data: candidates, isLoading: candidateLoading } = useGenericQuery(
    ['getCandidatesListForChat', candidatePage, debouncedCanidateSearchText],
    () =>
      getCandidatesListForChat(
        String(candidatePage + 1),
        debouncedCanidateSearchText,
      ),
    { enabled: filterModalOpen },
  );

  const { data: panFormDetail, isLoading: panFormLoading } = useGenericQuery(
    ['personnel-action-form-history', panFormId],
    () => getPanFormById(panFormId),
    { enabled: !!panFormId },
  );

  useEffect(() => {
    if (debouncedCanidateSearchText) {
      setCandidatePage(0);
      setCandidateList([]);
    }
    if (typeof candidates === 'object') {
      const newCandidates = candidates?.results?.map((item) => ({
        label: GetFormattedName<IUser>(item.userDetails),
        value: GetFormattedName<IUser>(item.userDetails),
        avatar: item.userDetails?.avatar as string,
      }));
      setCandidateList((prevCompanies) =>
        debouncedCanidateSearchText
          ? newCandidates
          : [...prevCompanies, ...newCandidates],
      );
    }
  }, [candidates, debouncedCanidateSearchText]);

  const candidateHasNextPage =
    candidatePage + 1 <
    (candidates as IPaginationResponse<ICandidate>)?.totalPages;

  const handleApproval = useGenericMutation<IApprovalPayload, string | boolean>(
    (payload) =>
      user?.subRole === SUB_ROLE.ACCOUNT_OFFICER
        ? accountOfficerApproval(payload)
        : recruiterManagerApproval(payload),
    {
      onSuccess: (response) => {
        if (typeof response === 'string') {
          toast.success(response);
          setSelectedRows([]);
          tableState?.resetRowSelection();
          setOpenApproveModal(false);
          setOpenRejectModal(false);
          refetch();
        }
      },
    },
  );

  const APPROVAL_FILTER_MODAL_FIELDS = getApprovalsFilterModalFields(
    candidateList,
    candidateLoading,
    candidateHasNextPage,
    setCandidatePage,
    setCandidateSearch,
    !!haveMoreRecruitersPage,
    recruiters,
    setRecruitersSearch,
    !!loadingRecruiters,
    setRecruiterPage,
    activeTab,
  );

  const { control, handleSubmit, reset, watch, setValue } =
    useForm<FilterOptions>({
      mode: 'all',
      defaultValues: Object.keys(approvalFilters)?.length
        ? approvalFilters
        : createInitialFilterState(APPROVAL_FILTER_MODAL_FIELDS),
    });

  const handleSelectionChange = useCallback((selectedRows: IApprovals[]) => {
    setSelectedRows(selectedRows);
  }, []);

  const handleResetFilter = () => {
    reset({
      ...createInitialFilterState(APPROVAL_FILTER_MODAL_FIELDS),
    });
    setApprovalFilters({});
  };

  const handleSubmitFilter: SubmitHandler<FilterOptions> = (data) => {
    setApprovalFilters({
      ...data,
      assignee: data?.assignee || '',
    });
    setFilterModalOpen(false);
  };

  const handleToggleFilterModal = () => {
    setFilterModalOpen(!filterModalOpen);
  };

  const handleRemoveTag = (keyToRemove: string) => {
    const updatedFilters = { ...approvalFilters };
    delete updatedFilters[keyToRemove];
    setApprovalFilters(updatedFilters);
    setValue(keyToRemove, '');
  };
  const handleHireOrReject = (action: string) => {
    const getStatus = () => {
      if (user?.subRole === SUB_ROLE.ACCOUNT_OFFICER) {
        return action === 'approve'
          ? AccountOfficerStatus.ACC_OFFICER_ACCEPTED
          : AccountOfficerStatus.ACC_OFFICER_REJECTED;
      }
      return action === 'approve'
        ? RecruiterManagerStatus.RECRUITER_MANAGER_ACCEPTED
        : RecruiterManagerStatus.RECRUITER_MANAGER_REJECTED;
    };
    const payload = {
      ids: selectedRows?.map((item) => item._id) || [],
      action,
      status: getStatus() as RecruiterManagerStatus | AccountOfficerStatus,
    };

    handleApproval.mutate(payload);
  };

  const handleConfirmation = (action: 'approve' | 'reject') => {
    handleHireOrReject(action);
  };

  const handleTabChange = (state: APPROVAL_TABS) => {
    setActiveTab(state);
    const path = generatePath(ROUTES.APPROVALS, { tab: state });
    navigate(path);
    setSelectedRows([]);
    tableState?.resetRowSelection();
    resetPagination();
  };

  useEffect(() => {
    if (typeof data === 'object') {
      setTabelData(data?.results);
    }
  }, [data]);

  useEffect(() => {
    if (!tab || !ApprovalTabsList.includes(tab as APPROVAL_TABS)) {
      const path = generatePath(ROUTES.APPROVALS, {
        tab: APPROVAL_TABS.PENDING,
      });
      navigate(path, { replace: true });
    } else {
      setActiveTab(tab);
      handleTabChange(tab as APPROVAL_TABS);
    }
  }, [tab, navigate]);

  useEffect(() => {
    if (hasValidFilters(approvalFilters) || approvalSearchText.length) {
      setPageIndex(0);
    }
  }, [approvalSearchText, approvalFilters]);

  const handlePanFormClose = () => {
    setPanFormId('');
  };

  const selectedApproval = useMemo(() => {
    return (data as IPaginationResponse<IApprovals>)?.results?.find(
      (approval) => approval._id === panFormId,
    );
  }, [data, panFormId]);

  return (
    <>
      {panFormId ? (
        <Modal
          open={!!panFormId}
          handleCloseModal={handlePanFormClose}
          title={staticText.panHistory}
        >
          <PersonnelActionHistory
            data={panFormDetail as IPersonnelActionFormData}
            isLoading={panFormLoading}
            ticketId={selectedApproval?.ticket?._id || ''}
            candidateId={selectedApproval?.candidate?._id || ''}
            job={selectedApproval?.ticket?.jobTitle || ''}
            mrfNumber={String(selectedApproval?.ticket?.ticketNumber || 0)}
            info={{
              ticketRate: selectedApproval?.ticket?.rate || 0,
              author: GetFormattedName(
                (selectedApproval?.ticket?.client as ClientData)
                  ?.companyClientId
                  ? (selectedApproval?.ticket?.client as ClientData)
                  : selectedApproval?.ticket?.author,
              ),
              typeOfEmployment:
                selectedApproval?.ticket?.typeOfEmployment || '',
            }}
          />
        </Modal>
      ) : null}
      <Container className='flex flex-col h-[calc(100vh-180px)]'>
        <HeaderWithSearchAndFilter
          searchText={approvalSearchText}
          setSearchText={setApprovalSearchText}
          toggleFilterModal={handleToggleFilterModal}
          haveFilterData={hasValidFilters(approvalFilters)}
          title={staticText.title}
          placeholder={staticText.headerSearchPlaceholder}
        />
        {hasValidFilters(approvalFilters) ? (
          <AppliedFilterSection
            tags={tags}
            className='mb-3'
            handleRemoveTag={handleRemoveTag}
          />
        ) : null}

        <div className='mt-5 flex-1 flex flex-col overflow-scroll'>
          <div className='flex justify-between'>
            <div className='flex gap-2'>
              {ApprovalTabsList.map((tab) => (
                <Button
                  key={tab}
                  variant={activeTab === tab ? 'default' : 'secondary'}
                  onClick={() => handleTabChange(tab as APPROVAL_TABS)}
                  className={cn(
                    'inline-flex items-center justify-center whitespace-nowrap rounded-md md:rounded-xl px-2 md:px-4 h-8 md:h-[40px] text-sm md:text-base transition-all disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300 capitalize',
                    {
                      'font-bold shadow-sm': activeTab === tab,
                    },
                  )}
                >
                  {snakeToTitleCase(tab)}
                </Button>
              ))}
            </div>
            {selectedRows?.length > 0 ? (
              <div className='flex gap-2 justify-end'>
                <Button
                  variant='outline'
                  size='sm'
                  className='h-8 px-2 md:px-auto md:h-[40px] text-base text-mouseGrey border-mouseGrey'
                  icon={<IoMdCloseCircle size={20} />}
                  onClick={() => setOpenRejectModal(true)}
                >
                  {isSmallScreen ? '' : staticText.reject}
                </Button>
                <Button
                  variant='outline'
                  size='sm'
                  className='h-8 px-2 md:px-auto md:h-[40px] text-base'
                  icon={<FaCheckCircle size={17} />}
                  onClick={() => setOpenApproveModal(true)}
                >
                  {isSmallScreen ? '' : staticText.approve}
                </Button>
              </div>
            ) : null}
          </div>
          <div className='flex-1 min-h-60 relative mt-4'>
            <div className='absolute inset-0 overflow-auto scrollbarHidden'>
              <DataTable
                columns={APPROVAL_TABLE_COLUMNS(
                  setPanFormId,
                  activeTab,
                  user!.subRole!,
                  setFromApprovalScreen,
                  setEditableCandidateData,
                )}
                data={tableData}
                onRowSelectionChange={handleSelectionChange}
                cellClassName='h-14 py-0 cursor-auto'
                setTablesState={setTableState}
                pageCount={
                  (data as IPaginationResponse<IApprovals>)?.totalPages
                }
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      </Container>
      {isLoading ? null : (
        <div className='flex justify-between items-center mt-3 md:mt-5 flex-col md:flex-row gap-2'>
          <TableSize
            tableState={tableState}
            setTableState={setTableState}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
          <TablePagination
            tableState={tableState}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        </div>
      )}
      {filterModalOpen ? (
        <FilterModal
          isOpen={filterModalOpen}
          onClose={handleToggleFilterModal}
          handleApplyFilter={handleSubmit(handleSubmitFilter)}
          control={control}
          handleResetFilter={handleResetFilter}
          buttonDisabled={Object.values(watch()).every((value) => !value)}
          fields={APPROVAL_FILTER_MODAL_FIELDS}
          searchText={approvalSearchText}
          handleClearSearch={() => setApprovalSearchText('')}
        />
      ) : null}
      {openApproveModal || openRejectModal ? (
        <ConfirmationModal
          open={openApproveModal || openRejectModal}
          primaryBtnLabel={staticText[openApproveModal ? 'approve' : 'reject']}
          handleCloseModal={() =>
            openApproveModal
              ? setOpenApproveModal(false)
              : setOpenRejectModal(false)
          }
          primaryBtnAction={() =>
            handleConfirmation(openApproveModal ? 'approve' : 'reject')
          }
          icon={
            openApproveModal ? (
              <FaCheckCircle className='size-6 text-primary' />
            ) : (
              <IoMdCloseCircle className='size-6 text-tomatoRed' />
            )
          }
          textDesc={staticText.confirmationDesc.replace(
            '{{decision}}',
            openApproveModal ? 'approve' : 'reject',
          )}
          containerClassName='py-5'
          isLoading={handleApproval.status === 'pending'}
        />
      ) : null}
    </>
  );
};

export default Approvals;
