import React from 'react';

import { ICandidateTag, ITag } from '@/@types/candidate';
import NameAndDesc from '@/components/NameAndDesc';
import StateIndicator from '@/components/StateIndicator';
import { useGenericQuery } from '@/hooks/useQueryData';
import { strings } from '@/locales';
import AttachmentPreview from '@/pages/Chat/ChatArea/AttachmentPreview';
import { fetchEndorsementDetail } from '@/services/candidate';

import FormConstantDataSection from './FormConstantDataSection';

interface IEndorsementHistorySection {
  isTicketScreen?: boolean;
  data?: ITag | ICandidateTag;
  candidateId?: string;
  ticketId?: string;
  mrfNumber?: string;
  job?: string;
}

const EndorsementHistorySection: React.FC<IEndorsementHistorySection> = ({
  isTicketScreen,
  data,
  candidateId,
  ticketId,
  mrfNumber,
  job,
}) => {
  const { ticketDetailScreen: staticText } = strings;

  const ticket =
    ticketId || (data as ICandidateTag)?.ticket?._id || (data as ITag)?.ticket;
  const candidate =
    candidateId ||
    (data as ICandidateTag)?.candidate?._id ||
    (data as ITag)?.candidate;

  const { data: endorsementDetail, isLoading } = useGenericQuery(
    ['endorsement-detail', candidate, ticket],
    () =>
      fetchEndorsementDetail({
        candidate: candidate as string,
        ticket: ticket as string,
      }),
  );

  const mimeType = endorsementDetail?.document?.split('.').pop();
  const isDocumentFile = ['pdf', '.docx']?.includes(mimeType!);

  if (isLoading) return <StateIndicator state='Loading' />;
  return (
    <div>
      {isTicketScreen ? (
        <FormConstantDataSection
          mrfNumber={String(
            (data as ITag)?.ticketNumber ||
              (data as ICandidateTag)?.ticket?.ticketNumber ||
              mrfNumber ||
              '',
          )}
          job={
            (data as ITag)?.jobPosition ||
            (data as ICandidateTag)?.ticket?.jobTitle ||
            job ||
            ''
          }
        />
      ) : null}
      <div className='flex gap-6 flex-col w-auto mt-4'>
        <NameAndDesc
          title={staticText.description}
          description={endorsementDetail?.description}
          avoidTruncate
          isLoading={isLoading}
        />
        {isDocumentFile ? (
          <AttachmentPreview
            key={'Attachment'}
            attachment={endorsementDetail?.document || ''}
          />
        ) : null}
      </div>
    </div>
  );
};

export default EndorsementHistorySection;
