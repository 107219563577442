/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { HiOutlineTrash } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import { FilterOptions, IDropdownOption, IPaginationResponse } from '@/@types';
import { IUser } from '@/@types/auth';
import ConfirmationModal from '@/components/ConfirmationModal';
import Container from '@/components/Container';
import { DataTable } from '@/components/DataTable';
import HeaderWithSearchAndFilter from '@/components/HeaderWithSearchAndFilter';
import TablePagination from '@/components/Table/TablePagination';
import TableSize from '@/components/Table/TableSize';
import { Button } from '@/components/ui/button';
import { COMMON_ERROR, USER_TYPE } from '@/constants';
import { generateUserTableFilterFields } from '@/constants/users';
import { useGenericMutation } from '@/hooks/useMutationData';
import { useGenericQuery } from '@/hooks/useQueryData';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useCommonDataContext } from '@/provider/CommonDataProvider';
import { useFilters } from '@/provider/FiltersProvider';
import { usePagination } from '@/provider/PaginationProvider';
import { ROUTES } from '@/routes';
import { deleteUser, fetchUsers } from '@/services/users';
import { createInitialFilterState, hasValidFilters } from '@/utils/common';
import { Table } from '@tanstack/react-table';

import AppliedFilterSection from '../Candidate/components/AppliedFilterSection';
import FilterModal from '../Candidate/components/FilterModal';

import { USERS_TABLE_COLUMNS } from './components/table/columns';

const UsersScreen = () => {
  const staticText = strings.usersScreen;

  const navigate = useNavigate();
  const {
    companies,
    haveMoreCompanyPage,
    setCompanyPage,
    loadingCompanies,
    setCompanySearch,
  } = useCommonDataContext();
  const { pageSize, setPageSize, pageIndex, setPageIndex } = usePagination();

  const { hasSubRole } = useAuth();
  const { userFilters, setUserFilters, userSearchText, setUserSearchText } =
    useFilters();
  const [tableData, setTableData] = useState<IUser[]>([]);
  const [idForDelete, setIdForDelete] = useState('');
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [tableState, setTableState] = useState<Table<IUser> | null>(null);

  const {
    control,
    reset,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isSubmitted },
  } = useForm<FilterOptions>({
    mode: 'all',
    defaultValues: createInitialFilterState([]),
  });

  const userRole = watch('role');

  const toggleFilterModal = () => setFilterModalOpen((prev) => !prev);

  const tags = useMemo(() => {
    return Object.entries(userFilters)
      .filter(([, value]) => value)
      .map(([key, value]) => {
        return {
          key,
          value:
            typeof value === 'object'
              ? ((value as IDropdownOption).label ?? '')
              : value || '',
        };
      });
  }, [userFilters]);

  const { data, isLoading, refetch } = useGenericQuery(
    [
      'users',
      pageSize,
      pageIndex,
      userSearchText,
      ...Object.values(userFilters),
    ],
    () =>
      fetchUsers({
        pageSize,
        page: pageIndex + 1,
        search: userSearchText,
        ...userFilters,
      }),
  );

  const handleRowClick = (data: IUser) => {
    navigate(`${ROUTES.USERS}/${data._id}`);
  };

  const handleFilter: SubmitHandler<FilterOptions> = (data) => {
    setUserFilters(data);
    setFilterModalOpen(false);
  };

  const resetFilter = () => {
    reset(createInitialFilterState(USER_TABLE_FILTER_FIELDS));
    setUserFilters({});
    refetch();
  };

  const handleCreate = () => navigate(ROUTES.CREATE_USER);

  useEffect(() => {
    if (typeof data === 'object') {
      setTableData(data?.results);
    }
  }, [data]);

  useEffect(() => {
    if (userRole === USER_TYPE.RECRUITER) {
      setValue('company', '');
    }
  }, [userRole]);

  const USER_TABLE_FILTER_FIELDS = generateUserTableFilterFields(
    companies,
    haveMoreCompanyPage,
    setCompanyPage,
    setCompanySearch,
    loadingCompanies,
    userRole as USER_TYPE,
  );

  useEffect(() => {
    if (Object.keys(userFilters).length > 0) {
      reset(userFilters);
    } else {
      reset(createInitialFilterState(USER_TABLE_FILTER_FIELDS));
    }
  }, []);

  const deleteUserMutation = useGenericMutation<string, boolean>(deleteUser, {
    onError: (error: unknown) => {
      toast.error(error instanceof Error ? error.message : COMMON_ERROR);
    },
    onSuccess: (response) => {
      if (response) {
        toast.success(staticText.deleteSuccess);
        setIdForDelete('');
        tableState?.resetRowSelection();
        refetch();
      } else {
        toast.error(COMMON_ERROR);
      }
    },
  });

  const handleDeleteModal = () => {
    deleteUserMutation.mutate(idForDelete);
  };

  const handleRemoveTag = (keyToRemove: string) => {
    setUserFilters({ ...userFilters, [keyToRemove]: '' });
    reset({ ...userFilters, [keyToRemove]: '' });
  };

  useEffect(() => {
    if (hasValidFilters(userFilters) || userSearchText.length) {
      setPageIndex(0);
    }
  }, [userSearchText, userFilters]);

  return (
    <div>
      {filterModalOpen ? (
        <FilterModal
          isOpen={filterModalOpen}
          onClose={toggleFilterModal}
          handleApplyFilter={handleSubmit(handleFilter)}
          control={control}
          handleResetFilter={resetFilter}
          filterErrors={errors}
          buttonDisabled={Object.values(watch()).every((value) => !value)}
          fields={USER_TABLE_FILTER_FIELDS}
          searchText={userSearchText}
          handleClearSearch={() => setUserSearchText('')}
        />
      ) : null}
      <Container className='flex flex-col h-[calc(100vh-180px)]'>
        <HeaderWithSearchAndFilter
          searchText={userSearchText}
          setSearchText={setUserSearchText}
          placeholder={staticText.headerSearchPlaceholder}
          toggleFilterModal={toggleFilterModal}
          title={staticText.title}
          haveFilterData={
            (isDirty && isSubmitted) || hasValidFilters(userFilters)
          }
        />
        {hasValidFilters(userFilters) ? (
          <AppliedFilterSection
            tags={tags}
            className='mb-3'
            handleRemoveTag={handleRemoveTag}
          />
        ) : null}
        {!hasSubRole ? (
          <div className='flex justify-end flex-none '>
            <Button onClick={handleCreate} className='w-full md:w-32'>
              {staticText.createUser}
            </Button>
          </div>
        ) : null}
        <div className='flex-1 min-h-60 relative mt-4'>
          <div className='absolute inset-0 overflow-auto scrollbarHidden'>
            <DataTable
              columns={USERS_TABLE_COLUMNS(setIdForDelete, hasSubRole)}
              data={tableData}
              handleRowClick={handleRowClick}
              pageCount={(data as IPaginationResponse<IUser>)?.totalPages}
              setTablesState={setTableState}
              loading={isLoading}
            />
          </div>
        </div>
      </Container>
      {isLoading ? null : (
        <div className='flex justify-between items-center mt-3 md:mt-5 flex-col md:flex-row gap-2'>
          <TableSize
            tableState={tableState}
            setTableState={setTableState}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
          <TablePagination
            tableState={tableState}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        </div>
      )}
      {idForDelete ? (
        <ConfirmationModal
          open={!!idForDelete}
          primaryBtnLabel={strings.common.delete}
          primaryBtnAction={handleDeleteModal}
          isLoading={deleteUserMutation.status === 'pending'}
          handleCloseModal={() => setIdForDelete('')}
          icon={<HiOutlineTrash className='size-6 text-tomatoRed' />}
          textDesc={strings.deleteText}
          containerClassName='py-5'
        />
      ) : null}
    </div>
  );
};

export default UsersScreen;
