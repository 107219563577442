import React, { useEffect, useState } from 'react';
import { BiSlider } from 'react-icons/bi';

import useDebounce from '@/hooks/useDebounce';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';

import { Button } from './ui/button';
import SearchBar from './SearchBar';
import TooltipComponent from './Tooltip';
import { Typography } from './Typography';

interface SearchAndFilterProps {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  toggleFilterModal: () => void;
  containerClassName?: string;
  buttonClassName?: string;
  haveFilterData: boolean;
  title: string;
  placeholder?: string;
}

const HeaderWithSearchAndFilter: React.FC<SearchAndFilterProps> = ({
  searchText,
  setSearchText,
  toggleFilterModal,
  containerClassName = '',
  buttonClassName = '',
  haveFilterData,
  title,
  placeholder,
}) => {
  const [searchTerm, setSearchTerm] = useState(searchText);
  const debouncedSearchText = useDebounce(searchTerm);

  useEffect(() => {
    setSearchText(debouncedSearchText);
  }, [debouncedSearchText, setSearchText]);

  useEffect(() => {
    if (!searchText.length) {
      setSearchTerm('');
    }
  }, [searchText]);

  return (
    <div className='flex-none flex gap-4 mb-4 flex-col sm:flex-row justify-between md:items-center'>
      <Typography className='text-xl md:text-[22px] font-bold'>
        {title}
      </Typography>
      <div
        className={cn(
          'flex gap-2 items-center justify-end',
          containerClassName,
        )}
      >
        {haveFilterData ? (
          <TooltipComponent
            align='center'
            className='py-2 px-4 w-52 sm:w-60 lg:w-80 text-xs'
          >
            {strings.common.filteredSearchInfo}
          </TooltipComponent>
        ) : null}
        <SearchBar
          searchText={searchTerm}
          setSearchText={setSearchTerm}
          containerClassName='sm:w-60 lg:w-80'
          placeholder={placeholder}
        />
        <Button
          icon={<BiSlider className='text-xl' />}
          variant='outline'
          className={cn(
            'border-[1.5px] p-0 w-12 relative rounded-md sm:rounded-auto sm:size-[42px]',
            buttonClassName,
          )}
          onClick={toggleFilterModal}
        >
          {haveFilterData ? (
            <div className='size-2 rounded-full absolute -top-1 -right-1 bg-tomatoRed' />
          ) : null}
        </Button>
      </div>
    </div>
  );
};

export default HeaderWithSearchAndFilter;
