import React, { useEffect, useState } from 'react';

import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  Table as TanStackTable,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';

import { BodyContent } from './BodyContent';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  pageCount: number;
  onRowSelectionChange?: (selectedRows: TData[]) => void;
  setTablesState?: React.Dispatch<
    React.SetStateAction<TanStackTable<TData> | null>
  >;
  handleRowClick?: (data: TData) => void;
  cellClassName?: string;
  loading?: boolean;
}

const CenterAlignedFields = ['tags', 'status', 'resumeUrl', 'role'];

export function DataTable<TData, TValue>({
  data,
  columns,
  loading,
  cellClassName,
  handleRowClick,
  setTablesState,
  onRowSelectionChange,
  pageCount,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});

  const handleTableRowClick = (data: TData) => {
    if (handleRowClick) {
      handleRowClick(data);
    }
  };

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    initialState: {
      pagination: {
        pageSize: 5,
        pageIndex: 0,
      },
    },
    pageCount,
    manualPagination: true,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  useEffect(() => {
    const selectedRows = table
      .getSelectedRowModel()
      .flatRows.map((row) => row.original);
    onRowSelectionChange?.(selectedRows);
  }, [rowSelection, onRowSelectionChange, table]);

  useEffect(() => {
    if (setTablesState) {
      setTimeout(() => {
        setTablesState(table);
      }, 1000);
    }
  }, [table]);

  return (
    <Table className='border-separate h-full border-spacing-y-3 max-w-full overflow-x-scroll overflow-y-hidden'>
      <TableHeader className='bg-secondary'>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead
                  className='text-nowrap'
                  key={header.id}
                  // set width for select checkbox column
                  style={{
                    width: header.id === 'select' ? '40px' : 'auto',
                    textAlign: CenterAlignedFields.includes(header.id)
                      ? 'center'
                      : 'start',
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        <BodyContent
          table={table}
          cellClassName={cellClassName}
          columnsLength={columns?.length}
          handleTableRowClick={handleTableRowClick}
          loading={loading}
        />
      </TableBody>
    </Table>
  );
}
