import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useSidebarContext } from './SidebarProvider';

interface ProviderProps {
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  pageIndex: number;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  resetPagination: () => void;
}

const PaginationContext = createContext<ProviderProps>({
  pageSize: 5,
  setPageSize: () => {},
  pageIndex: 0,
  setPageIndex: () => {},
  resetPagination: () => {},
});

export const PaginationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { currentTab } = useSidebarContext();

  const [pageSize, setPageSize] = useState(5);
  const [pageIndex, setPageIndex] = useState(0);

  const resetPagination = () => {
    setPageSize(5);
    setPageIndex(0);
  };

  useEffect(() => {
    resetPagination();
  }, [currentTab]);

  const value = {
    pageSize,
    setPageSize,
    pageIndex,
    setPageIndex,
    resetPagination,
  };

  return (
    <PaginationContext.Provider value={value}>
      {children}
    </PaginationContext.Provider>
  );
};

export const usePagination = (): ProviderProps => useContext(PaginationContext);
